import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <>
    <div className="tekup-preloader-wrap">
      <div className="tekup-preloader">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
    <header
      className="site-header tekup-header-section site-header--menu-right light-color"
      id="sticky-menu"
    >
      <div className="tekup-header-top bg-accent">
        <div className="container-fuild">
          <div className="tekup-header-info-wrap">
            <div className="tekup-header-info">
              <ul>
                <li>
                  <i className="ri-map-pin-2-fill" />
                  Chicago, Illinois
                </li>
                <li>
                  <a href="tel:6189124543">
                    <i className="ri-phone-fill" />
                    618 912 4543
                  </a>
                </li>
              </ul>
            </div>
            <div className="tekup-header-info">
              <ul>
                <li>
                  <i className="ri-time-fill" />
                  Office Hours: 9:00 AM – 4:00 PM
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fuild">
        <div className="tekup-header-bottom border_bottom">
          <nav className="navbar site-navbar">
            {/* Brand Logo*/}
            <div className="brand-logo">
              <a href="index.html">
                <img
                  src="assets/images/logo/logo-white.png"
                  alt=""
                  className="light-version-logo"
                />
              </a>
            </div>
            <div className="menu-block-wrapper">
              <div className="menu-overlay" />
              <nav className="menu-block" id="append-menu-header">
                <div className="mobile-menu-head">
                  <div className="go-back">
                    <i className="fa fa-angle-left" />
                  </div>
                  <div className="current-menu-title" />
                  <div className="mobile-menu-close">×</div>
                </div>
                <ul className="site-menu-main">
             
                  <li className="nav-item">
                    <a href="contact-us.html" className="nav-link-item">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="header-btn header-btn-l1 ms-auto d-none d-xs-inline-flex">
              <div className="tekup-header-icon">
                
                <div className="tekup-header-barger">
                  <span />
                </div>
              </div>
            </div>
            {/* mobile menu trigger */}
            <div className="mobile-menu-trigger light">
              <span />
            </div>
            {/*/.Mobile Menu Hamburger Ends*/}
          </nav>
        </div>
      </div>
      <div className="tekup-header-search-section">
        <div className="container">
          <div className="tekup-header-search-box">
            <input type="search" placeholder="Search here..." />
            <button id="header-search" type="button">
              <img src="assets/images/v1/search-dark.png" alt="" />
            </button>
            <p>Type above and press Enter to search. Press Close to cancel.</p>
          </div>
        </div>
        <div className="tekup-header-search-close">
          <i className="ri-close-line" />
        </div>
      </div>
    </header>
    <div className="search-overlay" />
    {/*End landex-header-section */}
    <div className="tekup-sidemenu-wraper">
      <div className="tekup-sidemenu-column">
        <div className="tekup-sidemenu-body">
          <div className="tekup-sidemenu-logo">
            <a href="">
              <img src="/assets/images/logo/logo-dark.png" alt="" style={{maxWidth:"200px"}} />
            </a>
          </div>
          <p>
          We create stunning, hassle-free websites for local businesses. With over a decade of experience, we handle everything from hosting and domain to unlimited support. 
          </p>
          <div className="tekup-sidemenu-thumb">
            <img src="/assets/images/hero/hero-image.jpg" alt="" />
          </div>
          <div className="tekup-contact-info-wrap">
           
            <div className="tekup-contact-info">
              <i className="ri-mail-fill" />
              <h5>Contact</h5>
              <a href="mailto:support@digime.us">support@digime.us</a>
              <a href="tel:6189124543">618 912 4543</a>
            </div>
          </div>
          {/*
          <div className="tekup-social-icon-box">
            <ul>
              <li>
                <a href="https://www.facebook.com/">
                  <i className="ri-facebook-fill" />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/">
                  <i className="ri-linkedin-fill" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/">
                  <i className="ri-twitter-fill" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/">
                  <i className="ri-instagram-fill" />
                </a>
              </li>
            </ul>
          </div>
           */}
        </div>
        <span className="tekup-sidemenu-close">
          <i className="ri-close-line" />
        </span>
      </div>
      <div className="offcanvas-overlay" />
    </div>
    <div className="offcanves-menu" />
    <div
      className="tekup-hero-section5"
      style={{ backgroundImage: "url(/assets/images/hero/hero-image.jpg)", backgroundPosition: "top" }}
    >
      <div className="container">
        <div className="row" id="contact-form-div">
          <div className="col-xl-6 col-lg-6 d-flex align-items-center">
            <div className="tekup-hero-content white-color">
              <h1>Get Online, Stress-Free</h1>
              <p>
                Making Websites Simple for Local Heroes Like You
              </p>
              <div className="tekup-extra-mt">
                <a className="tekup-default-btn tekup-white-btn scroll-to-name" href="">
                Get Started Now<i className="ri-arrow-right-up-line" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-5 offset-xl-2 offset-lg-1">
            <div className="tekup-hero-form">
              <h3>Get in touch</h3>
              <p>Free Consultation For IT Solutions</p><hr ></hr>
              <form  id="contact-form" className='row'>
                <div className="tekup-main-field col-6">
                  <input type="text" id="name" placeholder="Your name" />
                </div>
                <div className="tekup-main-field col-6">
                  <input type="text" id="phone" placeholder="Phone" />
                </div>
                <div className="tekup-main-field">
                  <input type="email" id="email" placeholder="Email address" />
                </div>
                <div className="tekup-main-field">
                  <textarea id="message"
                    name="textarea"
                    placeholder="How can we help?"
                    defaultValue={""}
                  />
                </div>
                <div className="text-danger" id="error-message"></div>
                <button id="contact-btn" type="submit">
                  Send Message <i className="ri-arrow-right-up-line" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End section */}
    <div className="section tekup-section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="tekup-thumb mr-30">
              <img src="/assets/images/index/img1.jpg" alt="" />
              
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center">
            <div className="tekup-default-content ml-60">
              <h2>Grow Your Business Online</h2>
              <p>
                We create websites that work for you, attracting new clients and increasing your bottom line. We handle everything technical - hosting, domain, and maintenance - so you can focus on running your business.
              </p>
              <div className="tekup-extra-mt">
                <a className="tekup-default-btn scroll-to-name" href="#">
                Get Started & Grow Today! <i className="ri-arrow-right-up-line" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End section */}
    <div className="section bg-light1 tekup-section-padding2">
      <div className="container">
        <div className="tekup-section-title center">
          <h2>We deal with the aspects of professional IT services</h2>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="tekup-iconbox-wrap3 bg-white">
              <div className="tekup-iconbox-icon3">
                <img src="assets/images/v2/icon3.png" alt="" />
              </div>
              <div className="tekup-iconbox-data3">
                <a href="single-service.html">
                  <h5>Modern Web Development</h5>
                </a>
                <p>
                  Attract new customers with a website that looks great and works flawlessly on all devices. We use latest technologies to build your website.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="tekup-iconbox-wrap3 bg-white">
              <div className="tekup-iconbox-icon3">
                <img src="assets/images/v2/icon4.png" alt="" />
              </div>
              <div className="tekup-iconbox-data3">
                <a href="single-service.html">
                  <h5>Custom Development</h5>
                </a>
                <p>
                  Need a unique feature like an AI chatbot or social media ad integration? We can build it for you! (Additional charges may apply)
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="tekup-iconbox-wrap3 bg-white">
              <div className="tekup-iconbox-icon3">
                <img src="assets/images/v2/icon7.png" alt="" />
              </div>
              <div className="tekup-iconbox-data3">
                <a href="single-service.html">
                  <h5>Hassle-Free Maintenance</h5>
                </a>
                <p>
                  Relax - hosting, domain, and updates are all taken care of. You focus on your business, we'll handle your website.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="tekup-iconbox-wrap3 bg-white">
              <div className="tekup-iconbox-icon3">
                <img src="assets/images/v2/icon6.png" alt="" />
              </div>
              <div className="tekup-iconbox-data3">
                <a href="single-service.html">
                  <h5>Unlimited Changes</h5>
                </a>
                <p>
                  Get expert help via email (support@digime.us) or phone (618) 912-4543. Ask for as many changes as you want.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End section */}
    <div className="section bg-dark tekup-section-padding3">
      <div id="tekup-counter" />
      <div className="container">
        <div className="tekup-counter-wrap4">
          <div className="tekup-counter-data4">
            <h2>
              <span data-percentage={9} className="tekup-counter" />+
            </h2>
            <p>Years of Experience</p>
          </div>
          <div className="tekup-counter-data4">
            <h2>
              <span data-percentage={54} className="tekup-counter" />+
            </h2>
            <p>Successfully Projects</p>
          </div>
          <div className="tekup-counter-data4">
            <h2>
              <span data-percentage={40} className="tekup-counter" />+
            </h2>
            <p>Satisfied Happy Clients</p>
          </div>
        </div>
      </div>
    </div> 
    <div className="section tekup-section-padding2">
      <div className="container">
        <div className="tekup-section-title center">
          <h2>Effective &amp; flexible pricing</h2>
        </div>
        <div className="row">
          <div className="col-xl-4 col-md-6">
            <div className="tekup-pricing-wrap all-border">
              <div className="tekup-pricing-header">
                <h4> Simple & Powerful </h4>
                <p>
                  Businesses who need a professional website with essential features.
                </p>
              </div>
              <div className="tekup-pricing-price mb-0">
                <h2>
                  $125<span>/month</span>
                </h2>
              </div>
              <div className="tekup-pricing-feature extra-mt">
                <ul>
                  <li>
                    <i className="ri-check-line" />
                    Hosting & Domain Included
                  </li>
                  <li>
                    <i className="ri-check-line" />
                    Modern & Responsive Design
                  </li>
                  <li>
                    <i className="ri-check-line" />
                    Easy Navigation
                  </li>
                  <li>
                    <i className="ri-check-line" />
                    Contact Form
                  </li>
                  <li>
                    <i className="ri-check-line" />
                    Unlimited Changes (included in monthly fee)
                  </li>
                  <li>
                    <i className="ri-check-line" />
                    Free Stock Photos
                  </li>
                  <li>
                    <i className="ri-check-line" />
                    Google My Business Integration
                  </li>
                  <li>
                    <i className="ri-check-line" />
                    Dedicated Support (via email & phone)
                  </li>
                </ul>
              </div>
              <a className="tekup-pricing-btn" href="https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-817955391F621151MMZPM74Y">
                Select This Package <i className="ri-arrow-right-up-line" />
              </a>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="tekup-pricing-wrap all-border">
              <div className="tekup-pricing-header">
                <h4>Custom Growth</h4>
                <p>
                  Businesses who need a unique website with advanced features.
                </p>
              </div>
              <div className="tekup-pricing-price mb-0">
                <h2>
                  $175<span>/month</span>
                </h2>
              </div>
              <div className="tekup-pricing-feature extra-mt">
                <ul>
                  <li>
                    <i className="ri-check-line" />
                    All Features from Simple & Powerful Plan
                  </li>
                  <li>
                    <i className="ri-check-line" />
                    Custom Design & Functionality
                  </li>
                  <li>
                    <i className="ri-check-line" />
                    E-commerce Integration (additional charges may apply)
                  </li>
                  <li>
                    <i className="ri-check-line" />
                    AI Chatbot Integration (additional charges may apply)
                  </li>
                   
                </ul>
              </div>
              <span>Custom development fees vary based on project complexity. Get a free quote today!</span><br /><br />
              <a className="tekup-pricing-btn active" href="https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-3EM239618Y862111DMZPNBHI">
                Select This Package <i className="ri-arrow-right-up-line" />
              </a>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="tekup-pricing-wrap all-border">
              <div className="tekup-pricing-header">
                <h4>Enterprise Plan</h4>
                <p>
                Do you have a specific vision for your website that goes beyond our pre-built plans? Our Enterprise Plan allows you to build a website entirely tailored to your needs. From advanced features to specific functionalities, we'll work with you to create a website that sets you apart.                </p>
              </div>
               
              <a className="tekup-pricing-btn scroll-to-name" href="pricing.html">
                Contact us <i className="ri-arrow-right-up-line" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End section */}
   
    {/* End section */}
    {/* Footer  */}
    <footer className="tekup-footer-section tekup-section-padding-top">
      <div className="container">

        <div className="tekup-footer-top tekup-section-padding">
          <div className="row">
            <div className="col-xl-4 col-lg-12">
              <div className="tekup-footer-textarea">
                <a href="index.html">
                  <img src="assets/images/logo/logo-dark.png" alt="" style={{maxWidth:"200px"}} />
                </a>
                <p>
                We create stunning, hassle-free websites for local businesses. With over a decade of experience, we handle everything from hosting and domain to unlimited support.
                </p>
                <div className="tekup-footer-info dark-color">
                  <ul>
                    <li>
                      <a href="tel:618912454323">
                        <i className="ri-phone-fill" />
                        618 912 4543
                      </a>
                    </li>
                    <li>
                      <a href="mailto:name@email.com">
                        <i className="ri-mail-fill" />
                        support@digime.us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-2 offset-xl-1 col-md-4">
              <div className="tekup-footer-menu">
                <div className="tekup-footer-title">
                  <h5>Quick Links</h5>
                </div>
                <ul>
                  <li>
                    <a href="#">About Us</a>
                  </li>
                  <li>
                    <a href="#">Our Team</a>
                  </li>
                  <li>
                    <a href="#">Pricing</a>
                  </li>
                  <li>
                    <a href="#">Blogs</a>
                  </li>
                  <li>
                    <a href="#">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-md-4">
              <div className="tekup-footer-menu extar-margin">
                <div className="tekup-footer-title">
                  <h5>Services</h5>
                </div>
                <ul>
                  <li>
                    <a href="#">UI/UX Design</a>
                  </li>
                  <li>
                    <a href="#">App Development</a>
                  </li>
                  <li>
                    <a href="#">Digital Marketing</a>
                  </li>
                  <li>
                    <a href="#">Web Development</a>
                  </li>
                  <li>
                    <a href="#">Cyber Security</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="tekup-footer-menu mb-0">
                <div className="tekup-footer-title">
                  <h5>Information</h5>
                </div>
                <ul>
                  <li>
                    <a href="">Working Process</a>
                  </li>
                  <li>
                    <a href="">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="">Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a href="faq.html">Faqs</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="tekup-footer-bottom dark">
          <div className="row">
            <div className="col-md-6">
              <div className="tekup-copywright">
                <p> © 2024 DigiMe Web Solutions. All rights reserved.</p>
              </div>
            </div>
            {/*
            <div className="col-md-6">
              <div className="tekup-social-icon-box style-three right">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/">
                      <i className="ri-facebook-fill" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/">
                      <i className="ri-linkedin-fill" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/">
                      <i className="ri-twitter-fill" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/">
                      <i className="ri-instagram-fill" />
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  </>
  
  );
}

export default App;
